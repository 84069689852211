
module.exports = {
    language: {
        name: '中文'
    },
    user: {
        home:'主页',
        game:'游戏',
        news:'动态',
        about:'关于',
        ABOUTUS:'关于我们',
        PRIVACYPOLICY:'隐私政策',
        RENRENGAMETERMSOFSERVICE:'人人游戏服务条款',
        CONTACTUS:'联系我们',
        news1content:'游戏1上线',
        news2content:'游戏2上线',
        news3content:'游戏3上线',
        gametitles1:'Isekai Fantasy',
        gameSubTitle1:'Restart with Monsters & Waifus',
        gametitles2:'大清立志传',
        gameSubTitle2:'为成为明星铺平道路！',
        gametitles3:'花咲く女帝の人生～転生の復讐少女～',
        gameSubTitle3:'中華後宮を体験できる着せ替えrpg',
        gametitles4:'Happy Dr ASMR: Fabulous Clinic',
        gameSubTitle4:'Give your clients a cosmetic makeover in this happy ASMR hospital you manage!',
        ADDRESS:"联系地址",
        PHONE:"联系电话",
        ADDRESSINFO:"ADDRESSINFO"
    }
}
