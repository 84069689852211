<template>
  <div class="home">
    <navbar class="navbar">
      <div slot="center">
        <a href="/">{{$t('user.home')}}</a>
        <el-dropdown>
          <a href="#">{{$t('user.game')}}</a>
          <el-dropdown-menu slot="dropdown" style="height:400px;width:85vw;background:black;margin: 5px 120px;border: 1px solid black;">
            <div class="glTitle"><i></i>&ensp;&ensp;<span>{{$t('user.game')}}</span></div>
            <br>
            <div class="demo-image">
              <div class="block1" v-for="url in urls" :key="url.img">
                <el-image
                  style="width: 207px; height: 126px"
                  :src="url.img"
                  fit="fill"></el-image>
              </div>
            </div>
          </el-dropdown-menu>
        </el-dropdown>
        <a href="/news">{{$t('user.news')}}</a>
        <router-link to="/info">
           {{$t('user.about')}}
        </router-link>
      </div>
      <div slot="right">
        <el-dropdown>
          <span class="el-dropdown-link">
            {{$t('language.name')}}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="changezhLanguage()">中文</el-dropdown-item>
            <el-dropdown-item @click.native="changeenLanguage()">EN</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </navbar>
    <div class="wrappers" ref="wrappers">
        <el-divider content-position="right"></el-divider>
        <div class="demo-image">
            <div class="block">
                <el-image :src="require('@/assets/img/info/renren_news.png')" style="width: 1200px; height: 700px"></el-image>
            </div>
        </div>
        <el-divider content-position="right"></el-divider>
        <div class="globalFooter___20TXn pcGlobalFooter___1zdQc" id="abouts">
          <div class="footerDesc___w-6gF">
            <img class="footerLogo___1aYWI" src="@/assets/img/logo/en-logo.png" alt="renrengames">
            <div class="pageItem___2aB6h">
              <el-button type="text" @click="linkUpdate" style="color:darkgrey">{{$t('user.ABOUTUS')}}</el-button>
              <i class="deviderLine___pJkWK"></i>
              <el-button type="text" @click="open" style="color:darkgrey">{{$t('user.PRIVACYPOLICY')}}</el-button>
              <i class="deviderLine___pJkWK"></i>
              <el-button type="text" @click="open" style="color:darkgrey">{{$t('user.RENRENGAMETERMSOFSERVICE')}}</el-button>
              <i class="deviderLine___pJkWK"></i>
              <el-button type="text" @click="open" style="color:darkgrey">{{$t('user.CONTACTUS')}}</el-button>
            </div>
            <div class="copyRight___39af_">© 2021, RenRen GAMES - ALL RIGHTS RESERVED</div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/home.css"
import BScroll from "better-scroll"
import Navbar from '../components/common/navbar/Navbar.vue'

export default {
  name: 'Home',
  mounted() {
    this.initScroll()
    var dom = document.querySelector('.wrappers');
    var block = document.querySelector('.block');
    console.log("width: ",dom.offsetWidth)
    console.log(window.screen.width)
    if(dom.offsetWidth > 2200){
        block.setAttribute("style","padding-left: 20%")
    }else if(dom.offsetWidth > 1920){
        block.setAttribute("style","padding-left: 17%")
    }else if(dom.offsetWidth > 1750){
        block.setAttribute("style","padding-left: 15%")
    }else if(dom.offsetWidth > 1550){
        block.setAttribute("style","padding-left: 12%")
    }else{
        block.setAttribute("style","padding-left: 5%")
    }
  },
  computed: {
      swiper() {
        return this.$refs.mySwiper.swiper;
      }
  },
  components: {
      Navbar,
  },
  data(){
    return{
      urls: [
          {
            img:require('@/assets/img/gamelist/nav.jpg'),
          },
          {
            img:require('@/assets/img/gamelist/nav1.jpg'),
          },
          {
            img:require('@/assets/img/gamelist/nav2.jpg'),
          },
          {
            img:require('@/assets/img/gamelist/nav3.jpg'),
          },
        ],
      swiperOption:{
        width : 370,
        preventClicks:false,
        
        // preventClicksPropagation:true,
        // paginationClickable:true,
        loop:false,
        spaceBetween : 5,
        notNextTick: true,
        direction:'horizontal',
        grabCursor: false,
        setWrapperSize: true,
        mousewheel: false,
        mousewheelControl: false,
        resistanceRatio: 0,
        slidesOffsetAfter:0,
        slidesPerGroup: 1,
        observeParents: true,
        debugger: true,
        onTransitionStart(swiper){
          console.log(swiper)
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    };
  },
  methods: {
      initScroll() {
        this.$nextTick(()=>{
          if(!this.Scroll) {
            this.Scroll = new BScroll(this.$refs.wrappers,{
              click: true,      // 配置允许点击事件
              scrollY: true     // 开启纵向滚动
            })
          } else {
            this.Scroll.refresh()    // 重新计算 better-scroll，当 DOM 结构发生变化的时确保滚动效果正常
          }
        })
      },
      changezhLanguage(){
        this.$i18n.locale ='zh'
        localStorage.setItem('languageSet',this.$i18n.locale) 
      },
      changeenLanguage(){
        this.$i18n.locale ='en'
        localStorage.setItem('languageSet',this.$i18n.locale) 
      },
      remind(){
          this.$toast(this.$t('user.loginUsername'))
      },
      prev(){
        this.$refs.mySwiper.$swiper.slidePrev();
      },
      next(){
          this.$refs.mySwiper.$swiper.slideNext();
      },
      open() {
        this.$alert(
          '<strong>Email: <i>rrserver@renrengame.com</i></strong>', 'Contact Us', 
        {
          dangerouslyUseHTMLString: true,
          showConfirmButton: false,
        });
      },
      linkUpdate(){
        this.$router.push({path:'/info'})
      }
    }
}
</script>

<style  scoped>
  /* .carousel .bannerDescContent::after {
      opacity: 1;
  } */
  /* .wrappers .demo-image .block{
      position: relative;
      padding-left: 15%;
      left: 18%;
  } */
  .gameDesc {
      font-family: Helvetica-Bold;
      font-size: 25px;
      width: 350px;
      height: 480px;
      color: rgb(5, 5, 5);
      position: absolute;
      bottom: 10px;
      left: 11px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-direction: column;
      z-index: 8;
  }
  .gameDesc:hover{
    background-image: linear-gradient(180deg,transparent,#000);
    z-index: 15;
  }
   
    .slideBox{
      position: relative;
      padding: 0 10px;
      left: 10px;
      height:500px;
    }
    .gameContainer{
     top: -30px;
     position: relative;
     height: 600px;
   }
   
   /* 底部指示器 */
 ::v-deep .el-carousel__button {
   border: 1px solid gray;
   width: 110px;
   height: 10px;
   /* border-radius: 50%; */
}
::v-deep .el-carousel__indicators--horizontal {
   bottom: 10px;
   left: auto;
   right: 0;
}
/* 左右箭头 */
::v-deep .el-carousel__arrow i {
 font-size: 40px;
}

::v-deep .el-carousel__arrow--right {
 right: 20px;
 border-top-right-radius: 4px;
 border-bottom-right-radius: 4px;
}

::v-deep .el-carousel__arrow--left {
 left: 20px;
 border-top-left-radius: 4px;
 border-bottom-left-radius: 4px;
}

::v-deep .el-carousel__arrow {
 background: gray;
 border-radius: 0;
 height: 100px;
}
.gameCard___2qqQK {
  z-index: 500;
}
.pcGlobalFooter___1zdQc .footerDesc___w-6gF {
    padding-bottom: 10px;
}
#abouts{
  height: 300px;
}
</style>