import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element'
import VueI18n from 'vue-i18n'
import vueAwesomeSwiper from 'vue-awesome-swiper'
// import 'bootstrap/dist/css/bootstrap.css'

Vue.use(VueI18n)
Vue.use(vueAwesomeSwiper)

const i18n=new VueI18n({
    locale:localStorage.getItem('languageSet')||'en',   //从localStorage里获取用户中英文选择，没有则默认英文
    messages:{
        'zh':require('./components/language/zh'),
        'en':require('./components/language/en')
    }
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
