<template>
  <div class="nav-bar">
    <div class="left">
        <slot name="left">
            <p align="left" style="padding-left: 50px;height:60px;"> <img src="@/assets/img/logo/en-logo.png" width="160px" height="60px" alt=""> </p>
        </slot>
    </div>
    <div class="center">
        <slot name="center"></slot>
    </div>
    <div class="right"><slot name="right"></slot></div>
  </div>
</template>

<script>
  export default {
    name: "NavBar",
    data() {
      return {

      };
    },
    
  }
</script>

<style>
.nav-bar{
    display: flex;
    padding: 0 20px;
    height: 60px;
    background: black;
    justify-content: space-around;
}
.left, .right{
    font-size: 22px;
    width: 30%;
    height: 60px;
    line-height: 60px;
}
.center{
    height: 60px;
    width: 40%;
}
.left img{
    text-align: left;
}
.center a{
    color: rgb(255, 255, 255);
    margin: 0 1vw;
    font-family: Helvetica;
    font-size: 20px;
    line-height: 60px;
}

</style>
