
module.exports = {
    language: {
        name: 'EN'
    },
    user: {
        home:'Home',
        game:'Games',
        news:'News',
        about:'About',
        ABOUTUS:'ABOUT US',
        PRIVACYPOLICY:'PRIVACY POLICY',
        RENRENGAMETERMSOFSERVICE:'RENRENGAME TERMS OF SERVICE',
        CONTACTUS:'CONTACT US',
        news1content:'game1up',
        news2content:'game2up',
        news3content:'game3up',
        gametitles1:'Isekai Fantasy',
        gameSubTitle1:'Restart with Monsters & Waifus',
        gametitles2:'Legend of Qing Dynasty',
        gameSubTitle2:'Pave your way toward stardom!',
        gametitles3:'花咲く女帝の人生',
        gameSubTitle3:'中華後宮を体験できる着せ替えrpg',
        gametitles4:'Happy Dr ASMR',
        gameSubTitle4:'Give your clients a cosmetic makeover in this happy ASMR hospital you manage!',
        ADDRESS:"ADDRESS",
        PHONE:"PHONE",
        ADDRESSINFO:"Room 257i, No. 668, Shangda Road, Baoshan District, Shanghai",
    }
}