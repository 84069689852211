<template>
  <div class="home">
    <navbar class="navbar">
      <div slot="center">
        <a href="/">{{$t('user.home')}}</a>
        <el-dropdown>
          <a href="#game">{{$t('user.game')}}</a>
          <el-dropdown-menu slot="dropdown" style="height:400px;width:85vw;background:black;margin: 5px 120px;border: 1px solid black;">
            <div class="glTitle"><i></i>&ensp;&ensp;<span>{{$t('user.game')}}</span></div>
            <br>
            <div class="demo-image">
              <div class="block1" v-for="url in urls" :key="url.img">
                <el-image
                  style="width: 207px; height: 126px"
                  :src="url.img"
                  fit="fill"></el-image>
              </div>
            </div>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- <a href="/news">{{$t('user.news')}}</a> -->
        <a href="#abouts">{{$t('user.about')}}</a>
      </div>
      <div slot="right">
        <el-dropdown>
          <span class="el-dropdown-link">
            {{$t('language.name')}}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="changezhLanguage()">中文</el-dropdown-item>
            <el-dropdown-item @click.native="changeenLanguage()">EN</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </navbar>
    <div class="wrapper" ref="wrapper">
        <div class="carousel" style="background: black;">
          <el-carousel indicator-position="outside" height="420px">
            <el-carousel-item v-for="item in carousels" :key="item.img">
              <a :href="item.lj" target="_blank" rel="noreferrer">
                <el-image
                  :src="item.img"
                  fit="contain"></el-image>
              </a>
              </el-carousel-item>
          </el-carousel>
        </div>
        <el-divider content-position="right"></el-divider>
        <div class="gameContainer" id="game">
          <div class="gameTitle">{{$t('user.game')}}</div>
          <div class="swiperbox">
            <swiper :options="swiperOption" ref="mySwiper" style="width:100vw" @click-slide="handleClickSlide">
                <swiper-slide class="slideBox" @click="url_jump" data="1">
                    <el-image :src="require('@/assets/img/gamecar/xgame1.jpg')" name="https://play.google.com/store/apps/details?id=com.isekai.us" style="width: 349px; height: 492px" fit="fill" >
                    </el-image>
                      <div class="gameDesc" data="https://play.google.com/store/apps/details?id=com.isekai.us">
                          <div class="gametitles">{{$t('user.gametitles1')}}</div>
                          <div class="gameSubTitle">{{$t('user.gameSubTitle1')}}</div>
                      </div>
                </swiper-slide>
                <swiper-slide class="slideBox">
                  <el-image :src="require('@/assets/img/gamecar/xgame2.jpg')" name="https://play.google.com/store/apps/details?id=com.daichinrisshiden.jp" style="width: 349px; height: 492px" fit="fill"></el-image>
                  <div class="gameDesc">
                        <div class="gametitles">{{$t('user.gametitles2')}}</div>
                        <div class="gameSubTitle">{{$t('user.gameSubTitle2')}}</div>
                  </div>
                </swiper-slide>
                <swiper-slide class="slideBox">
                  <el-image :src="require('@/assets/img/gamecar/xgame3.jpg')" name="https://play.google.com/store/apps/details?id=com.huakainvdi.gp.jp" style="width: 349px; height: 492px" fit="fill"></el-image>
                  <div class="gameDesc">
                        <div class="gametitles">{{$t('user.gametitles3')}}</div>
                        <div class="gameSubTitle">{{$t('user.gameSubTitle3')}}</div>
                  </div>
                </swiper-slide>
                <swiper-slide class="slideBox">
                  <el-image :src="require('@/assets/img/gamecar/xgame4.jpg')" name="https://play.google.com/store/apps/details?id=com.fabulousclinic.renren.gp.us&hl=en-us&gl=us" style="width: 349px; height: 492px" fit="fill"></el-image>
                  <div class="gameDesc">
                        <div class="gametitles">{{$t('user.gametitles4')}}</div>
                        <div class="gameSubTitle">{{$t('user.gameSubTitle4')}}</div>
                  </div>
                </swiper-slide>
              <div class="swiper-button-next swiper-button-black" slot="button-next" @click="next"></div>
              <div class="swiper-button-prev swiper-button-black" slot="button-prev" @click="prev"></div>
            </swiper>
          </div>
        </div>
        <el-divider content-position="right"></el-divider>
        <!-- <div class="news" id="new">
          <div class="newsTitle">{{$t('user.news')}}</div>
          <div class="newImgContainer">
            <div class="news1" >
              <div class="newimg">
              </div>
              <div class="newcontent">{{$t('user.news1content')}}</div>
              <div class="date">2021-12-31</div>
            </div>
            <div class="news2" >
              <div class="newimg"></div>
              <div class="newcontent">{{$t('user.news2content')}}</div>
              <div class="date">2021-12-31</div>
            </div>
            <div class="news3" >
              <div class="newimg"></div>
              <div class="newcontent">{{$t('user.news3content')}}</div>
              <div class="date">2021-12-31</div>
            </div>
          </div>
        </div> -->
        <div class="globalFooter___20TXn pcGlobalFooter___1zdQc" id="abouts">
          <div class="footerDesc___w-6gF">
            <img class="footerLogo___1aYWI" src="@/assets/img/logo/en-logo.png" alt="renrengames">
            <div class="pageItem___2aB6h">
              <el-button type="text" @click="linkUpdate" style="color:darkgrey">{{$t('user.ABOUTUS')}}</el-button>
              <i class="deviderLine___pJkWK"></i>
              <el-button type="text" style="color:darkgrey">
                <el-link type="info" href="http://web.fenghuangxinji.com/renrengame/renrengame.html">{{$t('user.PRIVACYPOLICY')}}</el-link>
              </el-button>
              <i class="deviderLine___pJkWK"></i>
              <el-button type="text" @click="open" style="color:darkgrey">
                <el-link type="info" href="http://web.fenghuangxinji.com/renrengame/renrengameterms.html">{{$t('user.RENRENGAMETERMSOFSERVICE')}}</el-link>
              </el-button>
              <i class="deviderLine___pJkWK"></i>
              <el-button type="text" @click="open" style="color:darkgrey">{{$t('user.CONTACTUS')}}</el-button>
            </div>
            <div class="address">
              <p><span>{{$t('user.ADDRESS')}}: </span>{{$t('user.ADDRESSINFO')}}</p>
              <p><span>{{$t('user.PHONE')}}: </span>010-86207925</p>
            </div>
            <div class="copyRight___39af_">© 2021, RenRen GAMES - ALL RIGHTS RESERVED</div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/home.css"
import Navbar from '../components/common/navbar/Navbar.vue'
import BScroll from "better-scroll"
import { swiper, swiperSlide } from "vue-awesome-swiper"
import "swiper/dist/css/swiper.css"

export default {
  name: 'Home',
  mounted() {
    this.initScroll()
    let swiper = this.$refs.mySwiper.swiper
    console.log("realIndex: ",swiper.realIndex)
    this.$refs.mySwiper.swiper.on('click',function(e){
      const url_dump=e.target.name
      if(url_dump){
        window.location=url_dump
      }
    })
  },
  computed: {
      swiper() {
        return this.$refs.mySwiper.swiper;
      }
  },
  components: {
    Navbar,
    swiper,
    swiperSlide,
  },
  data(){
    return{
      urls: [
          {
            img:require('@/assets/img/gamelist/nav.jpg'),
          },
          {
            img:require('@/assets/img/gamelist/nav1.jpg'),
          },
          {
            img:require('@/assets/img/gamelist/nav2.jpg'),
          },
          {
            img:require('@/assets/img/gamelist/nav3.jpg'),
          },
        ],
      urlss:[
          {
            img:require('@/assets/img/gamelist/nav.jpg'),
          },
          {
            img:require('@/assets/img/gamelist/nav1.jpg'),
          },
          {
            img:require('@/assets/img/gamelist/nav2.jpg'),
          },
          {
            img:require('@/assets/img/gamelist/nav3.jpg'),
          },
      ],
      carousels:[
        {
          bannerTitle:"游戏标题1",
          bannerTDesc:"内容1",
          img:require('@/assets/img/carousel/1.jpg'),
          lj:"https://play.google.com/store/apps/details?id=com.isekai.us",
        },
        {
          bannerTitle:"游戏标题2",
          bannerTDesc:"内容2",
          img:require('@/assets/img/carousel/2.jpg'),
          lj:"https://play.google.com/store/apps/details?id=com.isekai.us",
        },
        {
          bannerTitle:"游戏标题3",
          bannerTDesc:"内容3",
          img:require('@/assets/img/carousel/3.jpg'),
          lj:"https://play.google.com/store/apps/details?id=com.huakainvdi.gp.jp",
        },
        {
          bannerTitle:"游戏标题4",
          bannerTDesc:"内容4",
          img:require('@/assets/img/carousel/4.jpg'),
          lj:"https://play.google.com/store/apps/details?id=com.fabulousclinic.renren.gp.us&hl=en-us&gl=us",
        },
      ],
      swiperOption:{
        width : 370,
        preventClicks:false,
        //preventClicksPropagation:true,
        //paginationClickable:true,
        //preventLinksPropagation:false,
        loop:false,
        spaceBetween : 5,
        notNextTick: true,
        direction:'horizontal',
        grabCursor: false,
        setWrapperSize: true,
        mousewheel: false,
        mousewheelControl: false,
        resistanceRatio: 0,
        slidesOffsetAfter:0,
        slidesPerGroup: 1,
        observeParents: true,
        debugger: true,
        onTransitionStart(swiper){
          console.log(swiper)
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        // on:{
        //   click(e){
        //     let url=e.target.dataset.itemid;
        //     _this.goInto(url);
        //   }
        // }
      }
    };
  },
  methods: {
      handleClickSlide(index,reallyIndex){
        console.log(index,reallyIndex)
      },
      initScroll() {
        this.$nextTick(()=>{
          if(!this.Scroll) {
            this.Scroll = new BScroll(this.$refs.wrapper,{
              click: true,      // 配置允许点击事件
              scrollY: true     // 开启纵向滚动
            })
          } else {
            this.Scroll.refresh()    // 重新计算 better-scroll，当 DOM 结构发生变化的时确保滚动效果正常
          }
        })
      },
      changezhLanguage(){
        this.$i18n.locale ='zh'
        localStorage.setItem('languageSet',this.$i18n.locale) 
      },
      changeenLanguage(){
        this.$i18n.locale ='en'
        localStorage.setItem('languageSet',this.$i18n.locale) 
      },
      remind(){
          this.$toast(this.$t('user.loginUsername'))
      },
      prev(){
        this.$refs.mySwiper.$swiper.slidePrev();
      },
      next(){
          this.$refs.mySwiper.$swiper.slideNext();
      },
      open() {
        this.$alert(
          '<strong>ADRESS: <i>Room 257i, No. 668, Shangda Road, Baoshan District, Shanghai</i></strong><br/><strong>PHONE: <i>010-86207925</i></strong>',
          'Contact Us',
        {
          dangerouslyUseHTMLString: true,
          showConfirmButton: false,
        });
      },
      url_jump(){
        console.log("1111111111")
      },
      // pri_url(){
      //   this.$router.push({path:'http://web.fenghuangxinji.com/renrengame/renrengame.html'})
      // },
      linkUpdate(){
        this.$router.push({path:'/info'})
      }
    }
}
</script>

<style  scoped>
  /* .carousel .bannerDescContent::after {
      opacity: 1;
  } */
  .gameDesc {
      font-family: Helvetica-Bold;
      font-size: 25px;
      width: 360px;
      height: 485px;
      color: rgb(5, 5, 5);
      position: absolute;
      bottom: 5px;
      left: 9px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-direction: column;
      z-index: 8;
  }
  .gameDesc:hover{
    background-image: linear-gradient(180deg,transparent,#000);
    z-index: 15;
  }
   .swiperbox .slideBox .gameDesc .gametitles{
     color: #fff;
     height:100px;
     line-height: 100px;
   }
  .swiperbox .slideBox .gameDesc .gameSubTitle{
     color: #fff;
     font-size: 18px;
     height:130px;
     line-height: 70px;
   }
    .swiper-button-disabled{
      opacity: 0;
    }
    .swiperbox{
      position: relative;
      left: 10px;
      top: 20px;
    }
    .slideBox{
      position: relative;
      padding: 0 15px;
      left: 10px;
      height:500px;
    }
    .gameContainer{
     top: -30px;
     position: relative;
     height: 600px;
   }
    /* .news{
     top: -30px;
     position: relative;
     height: 500px;
   }
   .news .newsTitle{
     position: relative;
     left:50px;
     text-align:left;
     width:100%;
     font-size: 35px;
     height:80px;
     line-height: 80px;
   }
   .news .newImgContainer{
     display: flex;
     height: 450px;
     margin-left: 50px;
   }
   .news1,.news2,.news3{
     margin: 0 10px;
     background: rgb(204, 202, 202);
     height: 400px;
     width: 472px;
   }
   .newimg{
     position: relative; 
     transition: all 1.6s;
     background: rgb(160, 78, 78);
     height: 262px;
   }
   .newimg:hover{ 
     transform: scale(0.9);
   }
   .newcontent{
     text-align: center;
     font-family: Helvetica-Bold;
     font-size: 25px;
     height: 100px;
     line-height: 70px;
   }
   .date{
     text-align: left;
     font-family: Helvetica-Bold;
     font-size: 18px;
     height: 40px;
     line-height: 40px;
     margin-left: 25px;
   } */
   /* 底部指示器 */
 ::v-deep .el-carousel__button {
   border: 1px solid gray;
   width: 110px;
   height: 10px;
   /* border-radius: 50%; */
}
::v-deep .el-carousel__indicators--horizontal {
   bottom: 10px;
   left: auto;
   right: 0;
}
/* 左右箭头 */
::v-deep .el-carousel__arrow i {
 font-size: 40px;
}

::v-deep .el-carousel__arrow--right {
 right: 20px;
 border-top-right-radius: 4px;
 border-bottom-right-radius: 4px;
}

::v-deep .el-carousel__arrow--left {
 left: 20px;
 border-top-left-radius: 4px;
 border-bottom-left-radius: 4px;
}

::v-deep .el-carousel__arrow {
 background: gray;
 border-radius: 0;
 height: 100px;
}
.gameCard___2qqQK {
  z-index: 500;
}
/* #abouts{
  height: 880px;
} */
</style>