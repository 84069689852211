<template>
  <div class="home">
    <navbar class="navbar">
      <div slot="center">
        <a href="/">{{$t('user.home')}}</a>
        <el-dropdown>
          <a href="#">{{$t('user.game')}}</a>
          <el-dropdown-menu slot="dropdown" style="height:400px;width:85vw;background:black;margin: 5px 120px;border: 1px solid black;">
            <div class="glTitle"><i></i>&ensp;&ensp;<span>{{$t('user.game')}}</span></div>
            <br>
            <div class="demo-image">
              <div class="block1" v-for="url in urls" :key="url.img">
                <el-image
                  style="width: 207px; height: 126px"
                  :src="url.img"
                  fit="fill"></el-image>
              </div>
            </div>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- <a href="/news">{{$t('user.news')}}</a> -->
        <router-link to="/info">
           {{$t('user.about')}}
        </router-link>
      </div>
      <div slot="right">
        <el-dropdown>
          <span class="el-dropdown-link">
            {{$t('language.name')}}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="changezhLanguage()">中文</el-dropdown-item>
            <el-dropdown-item @click.native="changeenLanguage()">EN</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </navbar>
    <div class="wrappers" ref="wrappers">
        <el-divider content-position="right"></el-divider>
            <div class="block">
              <!-- <el-image :src="require('@/assets/img/info/renren.png')" style="width: 1200px; height: 700px"></el-image> -->
              <p>上海人人游戏科技发展股份有限公司（人人游戏）是一家成立于2007年的游戏开发公司，前身为人人公司游戏事业部。我们以游戏业为基础，持续耕耘十余年，研发并发行了80余款游戏产品，覆盖全球500多个国家地区，20多种语言，累计全球用户超过5亿。</p>
              <p>2016年，我们完成了VIE拆分并成功登陆国内资本市场。在游戏业务的基础上，我们通过资本积极布局产业链上下游，努力成为全球领先的互动娱乐产业平台。</p>
              <p>2018年起，我们开始进军日本手游市场，在男性向女性向宫斗/官斗游戏市场取得成功，进一步扩大了市场覆盖范围。2019年，我们在台港澳地区发行多款挂机类游戏，业务实现全面增长。2021年，我们进军欧美手游市场，在欧美女性向市场上占有一席之地。</p>
              <p>Shanghai RenRen Games Technology Development Co., Ltd (Renren Games) is a game development company established in 2007, formerly known as the game business unit of Renren Inc. We have been focusing on the game industry for over a decade, and have developed and published over 80 game products, covering over 500 countries and regions worldwide and available in over 20 languages. Our cumulative global user base exceeds 500 million.</p>
              <p>In 2016, we completed VIE split and successfully listed on the domestic capital market. Based on our gaming business, we actively expanded upstream and downstream of the industrial chain through capital, striving to become a global leader in interactive entertainment.</p>
              <p>
Since 2018, we have entered the Japanese mobile game market, achieving success in the oversea SLG game market, and further expanding our market coverage. In 2019, we released multiple AFK-like mobile games in Taiwan, Hong Kong and Macau, achieving comprehensive growth in our business. In 2021, we entered the European and American mobile game market and gained a foothold in the European and American female-oriented gaming market.</p>
          </div>
        <el-divider content-position="right"></el-divider>
        <div class="globalFooter___20TXn pcGlobalFooter___1zdQc" id="abouts">
          <div class="footerDesc___w-6gF">
            <img class="footerLogo___1aYWI" src="@/assets/img/logo/en-logo.png" alt="renrengames">
            <div class="pageItem___2aB6h">
              <el-button type="text" @click="linkUpdate" style="color:darkgrey">{{$t('user.ABOUTUS')}}</el-button>
              <i class="deviderLine___pJkWK"></i>
              <el-button type="text" style="color:darkgrey">
                <el-link type="info" href="http://web.fenghuangxinji.com/renrengame/renrengame.html">{{$t('user.PRIVACYPOLICY')}}</el-link>
              </el-button>
              <i class="deviderLine___pJkWK"></i>
              <el-button type="text" @click="open" style="color:darkgrey">
                <el-link type="info" href="http://web.fenghuangxinji.com/renrengame/renrengameterms.html">{{$t('user.RENRENGAMETERMSOFSERVICE')}}</el-link>
              </el-button>
              <i class="deviderLine___pJkWK"></i>
              <el-button type="text" @click="open" style="color:darkgrey">{{$t('user.CONTACTUS')}}</el-button>
            </div>
            <div class="address">
              <p><span>联系地址: </span>上海宝山区上大路668号257i室</p>
              <p><span>联系电话: </span>010-86207925</p>
            </div>
            <div class="copyRight___39af_">© 2021, RenRen GAMES - ALL RIGHTS RESERVED</div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/home.css"
import BScroll from "better-scroll"
import Navbar from '../components/common/navbar/Navbar.vue'

export default {
  name: 'Home',
  mounted() {
    this.initScroll()
    // var dom = document.querySelector('.wrappers');
    // var block = document.querySelector('.block');
    // console.log("width: ",dom.offsetWidth)
    // console.log(window.screen.width)
    // if(dom.offsetWidth > 2200){
    //     block.setAttribute("style","padding-left: 20%")
    // }else if(dom.offsetWidth > 1920){
    //     block.setAttribute("style","padding-left: 17%")
    // }else if(dom.offsetWidth > 1750){
    //     block.setAttribute("style","padding-left: 15%")
    // }else if(dom.offsetWidth > 1550){
    //     block.setAttribute("style","padding-left: 12%")
    // }else{
    //     block.setAttribute("style","padding-left: 5%")
    // }
  },
  computed: {
      swiper() {
        return this.$refs.mySwiper.swiper;
      }
  },
  components: {
      Navbar,
  },
  data(){
    return{
      urls: [
          {
            img:require('@/assets/img/gamelist/nav.jpg'),
          },
          {
            img:require('@/assets/img/gamelist/nav1.jpg'),
          },
          {
            img:require('@/assets/img/gamelist/nav2.jpg'),
          },
          {
            img:require('@/assets/img/gamelist/nav3.jpg'),
          },
        ],
      swiperOption:{
        width : 370,
        preventClicks:false,
        
        // preventClicksPropagation:true,
        // paginationClickable:true,
        loop:false,
        spaceBetween : 5,
        notNextTick: true,
        direction:'horizontal',
        grabCursor: false,
        setWrapperSize: true,
        mousewheel: false,
        mousewheelControl: false,
        resistanceRatio: 0,
        slidesOffsetAfter:0,
        slidesPerGroup: 1,
        observeParents: true,
        debugger: true,
        onTransitionStart(swiper){
          console.log(swiper)
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    };
  },
  methods: {
      initScroll() {
        this.$nextTick(()=>{
          if(!this.Scroll) {
            this.Scroll = new BScroll(this.$refs.wrappers,{
              click: true,      // 配置允许点击事件
              scrollY: true     // 开启纵向滚动
            })
          } else {
            this.Scroll.refresh()    // 重新计算 better-scroll，当 DOM 结构发生变化的时确保滚动效果正常
          }
        })
      },
      changezhLanguage(){
        this.$i18n.locale ='zh'
        localStorage.setItem('languageSet',this.$i18n.locale) 
      },
      changeenLanguage(){
        this.$i18n.locale ='en'
        localStorage.setItem('languageSet',this.$i18n.locale) 
      },
      remind(){
          this.$toast(this.$t('user.loginUsername'))
      },
      prev(){
        this.$refs.mySwiper.$swiper.slidePrev();
      },
      next(){
          this.$refs.mySwiper.$swiper.slideNext();
      },
      open() {
        this.$alert(
          '<strong>Email: <i>rrserver@renrengame.com</i></strong>', 'Contact Us', 
        {
          dangerouslyUseHTMLString: true,
          showConfirmButton: false,
        });
      },
      linkUpdate(){
        this.$router.push({path:'/info'})
      }
    }
}
</script>

<style  scoped>
  /* .carousel .bannerDescContent::after {
      opacity: 1;
  } */
  .wrappers .block{
      text-align: left;
      padding-left: 10%;
      width: 90%;
  }
  .wrappers .block p{
    margin-top: 30px;
    font-family: Helvetica-Bold;
  }
  .gameDesc {
      font-family: Helvetica-Bold;
      font-size: 25px;
      width: 350px;
      height: 480px;
      color: rgb(5, 5, 5);
      position: absolute;
      bottom: 10px;
      left: 11px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-direction: column;
      z-index: 8;
  }
  .gameDesc:hover{
    background-image: linear-gradient(180deg,transparent,#000);
    z-index: 15;
  }
   
    .slideBox{
      position: relative;
      padding: 0 10px;
      left: 10px;
      height:500px;
    }
    .gameContainer{
     top: -30px;
     position: relative;
     height: 600px;
   }
   
   /* 底部指示器 */
 ::v-deep .el-carousel__button {
   border: 1px solid gray;
   width: 110px;
   height: 10px;
   /* border-radius: 50%; */
}
::v-deep .el-carousel__indicators--horizontal {
   bottom: 10px;
   left: auto;
   right: 0;
}
/* 左右箭头 */
::v-deep .el-carousel__arrow i {
 font-size: 40px;
}

::v-deep .el-carousel__arrow--right {
 right: 20px;
 border-top-right-radius: 4px;
 border-bottom-right-radius: 4px;
}

::v-deep .el-carousel__arrow--left {
 left: 20px;
 border-top-left-radius: 4px;
 border-bottom-left-radius: 4px;
}

::v-deep .el-carousel__arrow {
 background: gray;
 border-radius: 0;
 height: 100px;
}
.gameCard___2qqQK {
  z-index: 500;
}
.pcGlobalFooter___1zdQc .footerDesc___w-6gF {
    padding-bottom: 10px;
}
#abouts{
  height: 300px;
}
</style>